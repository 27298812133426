import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/carly-pauline-bazemore.jpg";
  const fullName = "Carly Pauline Bazemore";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "October 12, 1987";
  const deathDate = "December 13, 2020";
  const birthDeathFooter = "";
  const memorialDate = "Saturday December 19th, 2020";
  const memorialTime = "4:30PM EST";
  const inviteText =
    "A life so beautifully lived deserves to be beautifully remembered. Please join us to commemorate the memory of Carly Bazemore.";
  const obituary = `Carly Pauline Bazemore, 33, passed away unexpectedly on December 13, 2020. She was a resident of Arden, North Carolina. Carly was preceded in death by her father, Randy Bazemore, her grandparents, John and Laverne Bazemore, Arthur Levy and Yetta Levy Blau. This beautiful angel will be remembered forever by her devoted mother, Marilyn Levy Bazemore; her loving brother, Scott Bazemore, sister-in-law Daisy Bazemore, and baby niece, Jasmine Pauline, of Broward County, Florida.
  \nAfter graduating from T.C. Roberson High School, Carly earned a Bachelor of Science degree from Clemson University and a Master of Arts degree in Applied Behavior Analysis, with a Certificate in Autism Studies from Ball State University. Carly was a member of the National Alliance on Mental Illness, the South Carolina Association for Behavior Analysis, and was a Board Member of the Autism Family Education Fund. As a practicing BCBA, Carly was living her dream of service to the autistic community. Carly will be remembered by her family, friends, and the community that she served, as a courageous, kind, generous, gentle, sweet soul, who was always there to lend them comfort and support. She was great-hearted, contributing to many charitable causes, walking the extra mile for those in need. To her friends and family she was a shoulder to cry on, a dispenser of good advice, a hug when you needed one. To the children that she served in her practice she was gentle, respectful, endlessly patient and totally committed to their development. Carly had a unique ability to relate with these special children and their families, always exhibiting empathy and encouragement. It was her gift. Carly found peace on the hiking trails in our mountains and kayaking on the Carolina lakes. She would light up a room and our hearts with her smile, and shake the walls with the sound of her laughter. Our angel was a shooting star, whose light brightened our lives for an all too brief amount of time. That light, and her memory will shine in our hearts and warm our days. She was loved…she will be missed…her memory will live on.`;
  const storiesRecordUrl = "https://stories.afterword.com/fbcd78gp4";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rutbxvxmdaogrwnnl37184ji0io0y0rvzdrwwp2g";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/carly-bazemore.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    >
      <h4>In remembrance of Carly Bazemore</h4>
      <p>
        The family asks that any charitable donations be made to{" "}
        <a
          href="https://www.felinelifelinesc.org/"
          target="_blank"
          rel="noreferrer"
        >
          Feline Lifeline
        </a>
        . She had a love for animals, working tirelessly to help feed them, find
        homes for them, and even taking in a kitten herself. This organization’s
        purpose is to give homeless cats a chance, something that Carly was so
        passionate about and poured her heart into.{" "}
      </p>
      <p>
        You can donate through{" "}
        <a
          href="https://m.facebook.com/sg/donate_check_out/?fundraiser_id=382941749463875&donate_ref=fundraiser_for_story&source_data%5Bsource_name%5D=fundraiser_for_story&source_data%5Bsource_id%5D=382941749463875&source_data%5Bquick_promotion_id%5D&source_data%5Bmsite_source_interface%5D=iphone&is_monthly_subscription=0&deeplink_mode=basic&redirected_for_ios=1&_rdr"
          target="_blank"
          rel="noreferrer"
        >
          their Facebook page
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
